import React, { FC } from 'react'

import BlogPost from '../modules/BlogPost/BlogPost'
import { HTMLContent } from '../components/Content/Content'
import { Helmet } from 'react-helmet'
import Layout from '../Layouts/Layout'
import { graphql } from 'gatsby'

const BlogPostTemplate: FC<{ data: GatsbyTypes.BlogPostByIDQuery }> = ({
  data,
}) => {
  const { markdownRemark } = data || {}
  const { html, frontmatter } = markdownRemark || {}
  const { description, tags, title } = frontmatter || {}

  return (
    <Layout>
      <Helmet titleTemplate="%s | Blog">
        <title>{`${title}`}</title>
        <meta name="description" content={`${description}`} />
      </Helmet>
      <BlogPost
        content={html}
        contentComponent={HTMLContent}
        description={description}
        tags={tags}
        title={title}
      />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featureImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
