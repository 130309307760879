import Content, { ContentProps } from '../../components/Content/Content'
import React, { FC, ReactElement } from 'react'

import Description from '../../components/Description/Description'
import { FluidImage } from '../../components/FluidImage/FluidImage'
import ReadingContainer from '../../components/ReadingContainer/ReadingContainer'
import Tags from '../Tags/Tags'
import Title from '../../components/Title/Title'
import styled from '@emotion/styled'

export type BlogPostProps = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatter,
  'description' | 'tags' | 'title'
> & {
  content: GatsbyTypes.MarkdownRemark['html'] | ReactElement | null
  contentComponent?: FC
  featureImage?: GatsbyTypes.MarkdownRemarkFrontmatter['featureImage']
}

const Article = styled.article``

const BlogPost: FC<BlogPostProps> = ({
  content,
  contentComponent,
  description,
  tags = [],
  title,
  featureImage,
}) => {
  const PostContent: FC<ContentProps> = contentComponent || Content
  return (
    <Article>
      {title && <Title>{title} </Title>}
      <ReadingContainer>
        <FluidImage imageInfo={featureImage} />
        {description && <Description>{description}</Description>}
        {tags.length > 0 ? <Tags tags={tags} /> : null}
        <PostContent content={content} />
      </ReadingContainer>
    </Article>
  )
}

export default BlogPost
